import React, { useEffect, useCallback, useRef, useState } from "react";
import "./CustomerPaymentLedger.css";
import { Select, DatePicker, Form, Button, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { getAllScmBrand } from "../../redux/actions/scmBrand.action";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { loadCustomers } from "../../redux/actions/customerForm";
import logo from "../../assets/images/logo-black.svg";
import btnPrint from "../../assets/images/button_print.gif";
import csv from "../../assets/images/csv.png";
import moment from "moment";
import { useParams } from "react-router-dom";
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getShippedStyleWiseSummary } from "../../redux/actions/shippedStyleWiseSummary.action";
import "../../../src/screens/ChargeBackType/chargeBackType.css";
import { useDownloadExcel } from "react-export-table-to-excel";
import xlsx from "../../assets/images/ico_xls_sm.gif";

const { Option } = Select;
const dateFormatList = ["MM/DD/YYYY"];

const ShippedStyleWiseSummary = ({ token }) => {
  TabTitle("Shipped StyleWise Summary");
  const userName = ReadCookie("name");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const focusInput = useRef(null);
  const [loader, setLoader] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const {
    customerForms,
    shippedStyleWiseSummary,
    style,
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const [customer, setCustomer] = useState("All");
  const { submited } = useParams();
  const tableRef = useRef(null);

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(
      loadCustomers(
        token,
        null,
        null,
        null,
        null,
        null,
        val,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      )
    ).then((res) => {
      if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
        setCustomer(res?.payload?.data);
      }
    });
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);

  let result =
    shippedStyleWiseSummary &&
    shippedStyleWiseSummary?.payload?.data?.reduce(function (r, a) {
      r[a.customer_id] = r[a.customer_id] || [];
      r[a.customer_id].push(a);
      return r;
    }, Object.create(null));

  const wiseStockReport = result && Object.values(result);

  console.log({ wiseStockReport });

  const onFinish = async (e) => {
    setLoader(true);
    await form.validateFields().then((values) => {
      dispatch(getShippedStyleWiseSummary(token, values)).then((res) => {
        setLoader(false);
        downloadExcelFileData(res?.payload?.data);
      });
    });
  };

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getAllScmBrand(token));
    dispatch(getCustomerType(token));
  }, [dispatch, menuId, token]);

  const handlePrint = () => {
    window.print();
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          Customer: v.customer_name,
          Style: v.style_name,
          "Men Qty": v.men_qty,
          "Men Amount": v.men_amount,
          "Women Qty": v.women_qty,
          "Women Amount": v.women_amount,
          "Total Qty": v.total_qty,
          "Total Amount": v.total_amount,
        };
      })
    );
  }
  const tableStyles = {
    width: "70%",
    borderCollapse: "collapse",
    fontSize: "10.5px",
    textAlign: "center",
    margin: "0 auto",
    tableLayout: "fixed",
  };

  const cellStyles = {
    border: "1px solid black",
    padding: "2px",
    paddingRight: "5px",
    fontWeight: "700",
  };

  const headerStyles = {
    ...cellStyles,
    backgroundColor: "#f2f2f2",
    fontWeight: "700",
  };

  const titleStyles = {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "bold",
    margin: "20px 0",
  };

  const formatter = (dataObj) => {
    let a = [];
    a = dataObj.map((element) => {
      let obj = {
        customerName: element[0]?.customer_name,
        totalMenQty: element.reduce(
          (sum, item) => sum + Number(item.men_qty),
          0
        ),
        totalMenAmount: element.reduce(
          (sum, item) => sum + Number(item.men_amount),
          0
        ),
        totalWomenQty: element.reduce(
          (sum, item) => sum + Number(item.women_qty),
          0
        ),
        totalWomenAmount: element.reduce(
          (sum, item) => sum + Number(item.women_amount),
          0
        ),
        totalQty: element.reduce(
          (sum, item) => sum + Number(item.total_qty),
          0
        ),
        totalAmount: element.reduce(
          (sum, item) => sum + Number(item.total_amount),
          0
        ),
        childs: element,
      };

      return obj;
    });
    return a
  };

  useEffect(() => {
    if (wiseStockReport?.length) {
      formatter(wiseStockReport);
    }
  }, [wiseStockReport]);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Shipped StyleWise Summary",
    sheet: "Sheet 1",
  });

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="report-wrapper-main header_class business_class ledger_print">
            <div className="row business_header justify-content-between pb-2 ledger_print">
              <div className="col-3 d-flex align-items-start  box-space">
                <div className="logobrand">
                  <img id="ImgLogo" alt="logo" src={logo} />
                </div>
              </div>
              <div className="col-4 box-space right-content-header text-right pb-0">
                <div className=" d-flex justify-content-end">
                  <div className=" d-flex printing_class mr-8">
                    <div className="print_icon printing_class">
                      <i
                        onClick={handlePrint}
                        id="Image1"
                        title="Print"
                        alt="Print"
                        src={btnPrint}
                        className="fa-solid fa-print"
                      ></i>
                    </div>
                    <div className="print_icon printing_class">
                      <input
                        type="image"
                        name="ImgExcel"
                        alt="Export to excel"
                        id="ImgExcel"
                        title="Export to excel"
                        src={xlsx}
                        onClick={onDownload}
                        style={{
                          borderWidth: "0px",
                          height: "14px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    {/* <div className="print_icon printing_class">
                      <CSVLink
                        filename={"Shipped-StyleWise-Summary.csv"}
                        data={excelData}
                        onClick={() => { }}
                      >
                        <input
                          type="image"
                          name="ImgExcel"
                          alt="Export to excel"
                          id="ImgExcel"
                          title="Export to excel"
                          src={csv}
                          style={{ borderWidth: "0px", height: "14px" }}
                        />
                      </CSVLink>
                    </div> */}
                  </div>
                  <span style={{ fontWeight: "bolder" }}>
                    Shipped StyleWise Summary
                    <br />
                    <div>
                      Rpt # 489
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="row  business_header ledger_print">
              <div className="col business_header">
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                  <div className="main_form row">
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          From:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="from_date"
                            initialValue={moment().startOf("year")}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          To:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="to_date"
                            initialValue={moment().endOf("year")}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main_form row">
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label className="labels width30">Customer:</label>
                        <div className=" w-100">
                          <Form.Item
                            name="customer_id"
                            initialValue={"All"}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Customer is Required",
                          //   },
                          // ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              className={"select_Inputs"}
                              loading={customerForms.loading}
                              size="small"
                              onSearch={(value) => {
                                optimizedFn(value);
                              }}
                              ref={focusInput}
                              value={customer}
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              <Option value={null}>All</Option>
                              {customerForms?.payload?.data.map((item) => {
                                return (
                                  <Option key={item.customer_id}>
                                    {`${item.customer_name}`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label className="labels width30" htmlFor="">
                          Style
                        </label>
                        <div className="w-100">
                          <Form.Item initialValue={"All"} name="style_id">
                            <Select
                              className={"select_Inputs"}
                              showSearch
                              optionFilterProp="children"
                              size="small"
                            >
                              <Option value={null}>All</Option>

                              {style &&
                                style?.payload?.data.map((style, index) => {
                                  return (
                                    <Option key={index} value={style.style_id}>
                                      {style.style_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div>
                        <Button
                          htmlType="submit"
                          type="primary"
                          className="submit-btn-btm "
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div
            className="table_content customer_ledger_table_content ledger_print"
            style={{ maxWidth: "1300px" }}
          >
            <div className="">
              {shippedStyleWiseSummary?.payload?.data !== undefined ? (
                shippedStyleWiseSummary?.payload?.data.length > 0 ? (
                  <div style={{ marginBottom: "10px" }} ref={tableRef}>
                    <div style={titleStyles}>
                      Warp Weft World Inc Shipped Report {moment(form.getFieldValue("from_date")).year() === moment(form.getFieldValue("to_date")).year()
                        ? moment(form.getFieldValue("from_date")).year()
                        : `${moment(form.getFieldValue("from_date")).year()} - ${moment(form.getFieldValue("to_date")).year()}`}
                    </div>
                    <table style={tableStyles} >
                      <thead className="boldHeader">
                        <tr style={{ position: "sticky", top: "0", backgroundColor: "#fff" }}>
                          <th style={{ ...headerStyles, width: "300px" }} rowSpan="2">
                            Customer
                          </th>
                          <th style={headerStyles} colSpan="2">
                            Men
                          </th>
                          <th style={headerStyles} colSpan="2">
                            Women
                          </th>
                          <th style={headerStyles} colSpan="2">
                            Total
                          </th>
                        </tr>
                        <tr style={{ position: "sticky", top: "20px", backgroundColor: "#fff" }}>
                          <th style={headerStyles}>Qty</th>
                          <th style={headerStyles}>Amount</th>
                          <th style={headerStyles}>Qty</th>
                          <th style={headerStyles}>Amount</th>
                          <th style={headerStyles}>Qty</th>
                          <th style={headerStyles}>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wiseStockReport?.length &&
                          formatter(wiseStockReport)?.map((e) => {
                            return (
                              <>
                                <tr style={{ fontWeight: 900 }}>
                                  <td className="boldTd" style={{ ...cellStyles, textAlign: "left", paddingLeft: "4px" }}>
                                    {e?.customerName}
                                  </td>
                                  <td className="boldTd" style={{ ...cellStyles, textAlign: "right" }}>
                                    {e?.totalMenQty}
                                  </td>
                                  <td className="boldTd" style={{ ...cellStyles, textAlign: "right" }}>
                                    $ {numeral(e?.totalMenAmount).format("0,0.00")}
                                  </td>
                                  <td className="boldTd" style={{ ...cellStyles, textAlign: "right" }}>
                                    {e?.totalWomenQty}
                                  </td>
                                  <td className="boldTd" style={{ ...cellStyles, textAlign: "right" }} >
                                    $ {numeral(e?.totalWomenAmount).format("0,0.00")}
                                  </td>
                                  <td className="boldTd" style={{ ...cellStyles, textAlign: "right" }}>
                                    {e?.totalQty}
                                  </td>
                                  <td className="boldTd" style={{ ...cellStyles, textAlign: "right" }}>
                                    $ {numeral(e?.totalAmount).format("0,0.00")}
                                  </td>
                                </tr>
                                {e?.childs.map((elem) => (
                                  <tr>
                                    <td style={{ ...cellStyles, textAlign: "left", textIndent: "10px" }}>
                                      {elem?.style_name}
                                    </td>
                                    <td style={{ ...cellStyles, textAlign: "right" }}>
                                      {elem?.men_qty}
                                    </td>
                                    <td style={{ ...cellStyles, textAlign: "right" }}>
                                      $ {numeral(elem?.men_amount).format("0,0.00")}
                                    </td>
                                    <td style={{ ...cellStyles, textAlign: "right" }}>
                                      {elem?.women_qty}
                                    </td>
                                    <td style={{ ...cellStyles, textAlign: "right" }}>
                                      $ {numeral(elem?.women_amount).format("0,0.00")}
                                    </td>
                                    <td style={{ ...cellStyles, textAlign: "right" }}>
                                      {elem?.total_qty}
                                    </td>
                                    <td style={{ ...cellStyles, textAlign: "right" }}>
                                      $ {numeral(elem?.total_amount).format("0,0.00")}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="no-Data">
                    <h1>No Data</h1>
                  </div>
                )
              ) : (
                loader && (
                  <div className="loader" style={{ marginTop: "20px" }}>
                    <Spin size="medium" />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippedStyleWiseSummary;

import {
    GET_SHIPPED_STYLE_WISE_SUMMARY_REQUEST,
    GET_SHIPPED_STYLE_WISE_SUMMARY_SUCCESS,
    GET_SHIPPED_STYLE_WISE_SUMMARY_ERROR
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getShippedStyleWiseSummary = (token, data) => (dispatch) => {

    dispatch({ type: GET_SHIPPED_STYLE_WISE_SUMMARY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/ShippedStyleGenderWise`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            customer_id: (data.customer_id === "All" || data.customer_id === undefined) ? null : data.customer_id,
            style_id:data.style_id === "All" ? null : data.style_id,
            from_date: moment(data.from_date).format("YYYY-MM-DD"),
            to_date: moment(data.to_date).format("YYYY-MM-DD"),
        }
    })
        .then((response) => {
            return dispatch({ type: GET_SHIPPED_STYLE_WISE_SUMMARY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_SHIPPED_STYLE_WISE_SUMMARY_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_SHIPPED_STYLE_WISE_SUMMARY_ERROR,
                payload: error.response,
            });
        });
};

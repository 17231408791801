import React, { useEffect, useState } from "react";
import { Spin, Select, DatePicker, Form, Button, Space, Tag } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import csv from "../../assets/images/csv.png";
import pdf from "../../assets/images/PDF_file_icon.svg.png";
import logo from "../../assets/images/logo-black.svg";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import { getChartOfAccount } from "../../redux/actions/Chart_All_Account.action";
import { getJournalLedger } from "../../redux/actions/journalLedger.action";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import jsPDF from 'jspdf';
import ExcelJS from "exceljs";
import { FixedSizeList as List } from 'react-window';
import { groupBy } from 'lodash'; // Import groupBy from lodash for grouping
import printJS from "print-js";
import { useReactToPrint } from 'react-to-print';
import { useDownloadExcel } from "react-export-table-to-excel";
import Excel from 'exceljs';


const VirtualizedAccountsListComp = ({ data }) => {
    // Step 1: Flatten the nested array of arrays into a single flat array
    const flattenedData = data?.flatMap(innerArray => innerArray);

    // Step 2: Group objects by `parent_account_no`
    const groupedData = groupBy(flattenedData, 'parent_account_no');

    // Initialize a variable to keep track of the previous balance
    let previousBalance = 0;

    // Step 3: Prepare the data for rendering
    const items = Object.entries(groupedData).reduce((acc, [parent_account_no, objects]) => {
        // Extract account_search_display_name from the first object in the group
        const firstObject = objects[0]; // Assuming there's at least one object in the group
        const account_search_display_name = firstObject?.account_search_display_name || '';

        // Push the parent account number as a heading along with account_search_display_name
        acc.push({ type: 'heading', parent_account_no, account_search_display_name });

        // Push each object in the group
        objects.forEach(obj => {
            const { debit, credit } = obj;
            const balance = previousBalance + debit - credit;
            previousBalance = balance; // Update previous balance for the next iteration
            acc.push({ type: 'object', data: obj, balance });
        });

        return acc;
    }, []);
    console.log("items", items);
    const getItemSize = index => {
        const item = items[index];
        return item.type === 'heading' ? 100 : 50;
    };

    // Function to render each row/item
    const Row = ({ index, style }) => {
        const item = items[index];
        if (!item) {
            return null;
        }

        if (item.type === 'heading') {
            return (
                <div style={{ ...style, height: "200px" }}>
                    <div style={{ fontWeight: "bold" }}>
                        <div style={{ fontWeight: "bold" }}>{item.parent_account_no}</div>
                        <div style={{ paddingLeft: 10, fontWeight: "bold" }}>{item.account_search_display_name}</div>
                    </div>
                </div>
            );
        } else {
            const { data } = item;

            return (
                <div style={{ ...style, margin: "0px auto !important", top: style.top + 20, display: "flex", flexDirection: "row", justifyContent: "space-around", fontSize: 10, textAlign: "left", borderBottom: "1px solid black" }}>
                    <tr >
                        <td style={{ width: 200 }}>{data.transaction_type}</td>
                        <td style={{ width: 200 }}>{data.transaction_date}</td>
                        <td style={{ width: 200 }}>{data.transaction_no}</td>
                        <td style={{ width: 200 }}>{data.customer_name}</td>
                        <td style={{ width: 200, textAlign: "right" }}>${numeral(data.debit).format("0,00.00")}</td>
                        <td style={{ width: 200, textAlign: "right" }}>${numeral(data.credit).format("0,00.00")}</td>
                        <td style={{ width: 200, textAlign: "right" }}>${numeral(data.balance).format("0,00.00")}</td>
                    </tr>
                </div>
            );
        }
    };

    return (
        <div style={{ width: '80%', margin: "0px auto", height: 600 }}>
            <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                <tr style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', textAlign: "center", background: "#3c4b58", color: "white" }}>
                    <td style={{ width: 200 }}>Transaction Type</td>
                    <td style={{ width: 200 }}>Transaction Date</td>
                    <td style={{ width: 200 }}>Transaction No</td>
                    <td style={{ width: 200 }}>Customer Name</td>
                    <td style={{ width: 200 }}>Debit</td>
                    <td style={{ width: 200 }}>Credit</td>
                    <td style={{ width: 200 }}>Balance</td>
                </tr>
            </div>
            <div>
                <List height={500} itemCount={items.length} itemSize={20} width={'100%'}>
                    {Row}
                </List>

            </div>
        </div>
    );
};
const VirtualizedAccountsListCompForPrint = ({ data }) => {
    // Step 1: Flatten the nested array of arrays into a single flat array
    const flattenedData = data?.flatMap(innerArray => innerArray);

    // Step 2: Group objects by `parent_account_no`
    const groupedData = groupBy(flattenedData, 'parent_account_no');

    // Initialize a variable to keep track of the previous balance
    let previousBalance = 0;

    // Step 3: Prepare the data for rendering
    const items = Object.entries(groupedData).reduce((acc, [parent_account_no, objects]) => {
        // Extract account_search_display_name from the first object in the group
        const firstObject = objects[0]; // Assuming there's at least one object in the group
        const account_search_display_name = firstObject?.account_search_display_name || '';

        // Push the parent account number as a heading along with account_search_display_name
        acc.push({ type: 'heading', parent_account_no, account_search_display_name });

        // Push each object in the group
        objects.forEach(obj => {
            const { debit, credit } = obj;
            const balance = previousBalance + debit - credit;
            previousBalance = balance; // Update previous balance for the next iteration
            acc.push({ type: 'object', data: obj, balance });
        });

        return acc;
    }, []);

    const getItemSize = index => {
        const item = items[index];
        return item.type === 'heading' ? 100 : 50;
    };

    // Function to render each row/item
    const Row = ({ index, style }) => {
        const item = items[index];
        if (!item) {
            return null;
        }

        if (item.type === 'heading') {
            return (
                <div style={{ ...style, height: "200px" }}>
                    <div style={{ fontWeight: "bold" }}>
                        <div style={{ fontWeight: "bold" }}>{item.parent_account_no}</div>
                        <div style={{ paddingLeft: 10, fontWeight: "bold" }}>{item.account_search_display_name}</div>
                    </div>
                </div>
            );
        } else {
            const { data } = item;

            return (
                <div style={{ ...style, margin: "0px auto !important", top: style.top + 20, display: "flex", flexDirection: "row", justifyContent: "space-around", fontSize: 10, textAlign: "left", borderBottom: "1px solid black" }}>
                    <tr >
                        <td style={{ width: 200 }}>{data.transaction_type}</td>
                        <td style={{ width: 200 }}>{data.transaction_date}</td>
                        <td style={{ width: 200 }}>{data.transaction_no}</td>
                        <td style={{ width: 200 }}>{data.customer_name}</td>
                        <td style={{ width: 200, textAlign: "right" }}>${numeral(data.debit).format("0,00.00")}</td>
                        <td style={{ width: 200, textAlign: "right" }}>${numeral(data.credit).format("0,00.00")}</td>
                        <td style={{ width: 200, textAlign: "right" }}>${numeral(data.balance).format("0,00.00")}</td>
                    </tr>
                </div>
            );
        }
    };

    return (
        <div style={{ width: '80%', margin: "0px auto", height: 600 }}>
            <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                <tr style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', textAlign: "center", background: "#3c4b58", color: "white" }}>
                    <td style={{ width: 200 }}>Transaction Type</td>
                    <td style={{ width: 200 }}>Transaction Date</td>
                    <td style={{ width: 200 }}>Transaction No</td>
                    <td style={{ width: 200 }}>Customer Name</td>
                    <td style={{ width: 200 }}>Debit</td>
                    <td style={{ width: 200 }}>Credit</td>
                    <td style={{ width: 200 }}>Balance</td>
                </tr>
            </div>
            <div>
                {/* <List height={500} itemCount={items.length} itemSize={20} width={'100%'}>
                    {Row}
                </List> */}
                <div style={{ height: 500, width: "100%" }}>
                    {/* <h1>aaaaaaaaaaaaaaa</h1> */}
                    {Row}
                </div>
            </div>
        </div>
    );
};


const formatedGroupedData = (data) => {
    // Step 1: Flatten the nested array of arrays into a single flat array
    const flattenedData = data?.flatMap(innerArray => innerArray);

    // Step 2: Group objects by `parent_account_no`
    const groupedData = groupBy(flattenedData, 'parent_account_no');

    // Initialize a variable to keep track of the previous balance
    let previousBalance = 0;

    // Step 3: Prepare the data for rendering
    const items = Object.entries(groupedData).reduce((acc, [parent_account_no, objects]) => {
        // Extract account_search_display_name from the first object in the group
        const firstObject = objects[0]; // Assuming there's at least one object in the group
        const account_search_display_name = firstObject?.account_search_display_name || '';

        // Push the parent account number as a heading along with account_search_display_name
        acc.push({ type: 'heading', parent_account_no, account_search_display_name });

        // Push each object in the group
        objects.forEach(obj => {
            const { debit, credit } = obj;
            const balance = previousBalance + debit - credit;
            previousBalance = balance; // Update previous balance for the next iteration
            acc.push({ type: 'object', data: obj, balance });
        });

        return acc;
    }, []);
    return items
}

export const JournalLedgerNew = ({ token }) => {
    TabTitle("General Ledger");
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {
        chartOfAccount, journalLedger
    } = useSelector((state) => state);
    const menuId = ReadCookie("menuId");
    const userName = ReadCookie("name");
    const [loading, setLoading] = useState(false);
    const [inventoryList, setInventoryList] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const { Option } = Select;







    const tableRef = useRef(null);


    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Balance Sheet",
        sheet: "Balance Sheet",
    });

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let account_id = query.get("account_id");
    let to_date = query.get("date_to");

    useEffect(() => {
        if (account_id) {
            let data = {
                account_id: account_id,
                to_date: moment(JSON.parse(to_date).to_date).format("MM-DD-YYYY"),
                from_date: "01-01-2023"
            };
            form.setFieldsValue({
                account_id: data.account_id,
                to_date: moment(data.to_date),
                from_date: moment(data.from_date),
            });
            setLoading(true);
            dispatch(getJournalLedger(token, data)).then((res) => {
                setLoading(false);
                setInventoryList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            });
        }
    }, [account_id]);

    const onFinish = async (e) => {
        setLoading(true);
        await form.validateFields().then((data) => {
            dispatch(getJournalLedger(token, data)).then((res => {
                setLoading(false);
                setInventoryList(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
            }));
        });
    };

    useEffect(() => {
        dispatch(getChartOfAccount(token));
    }, [dispatch, menuId, token]);



    const handlePrint = () => {
        console.log({ grouped })
        // const flattenedData = ddddd.flat(); // Flatten the array of arrays into a single array
        // const groupedData = {}; // Object to group data by parent_account_no

        // // Group data by parent_account_no
        // flattenedData.forEach(item => {
        //     const { parent_account_no } = item;
        //     if (!groupedData[parent_account_no]) {
        //         groupedData[parent_account_no] = [];
        //     }
        //     groupedData[parent_account_no].push(item);
        // });

        // // Prepare printable content
        // const printableContent = [];
        // for (const parentAccountNo in groupedData) {
        //     const parentAccount = parentAccountNo;
        //     const details = groupedData[parentAccountNo];

        //     // Add parent account details
        //     printableContent.push({ type: 'parent', parentAccount });

        //     // Add details of the parent account
        //     details.forEach(detail => {
        //         printableContent.push({ type: 'detail', detail });
        //     });
        // }
        // console.log("printableContent", printableContent)
        // // Print using printJS
        // // Flatten the nested array
        // const flattenedArray = grouped.flat();

        // // Convert each item into an object structure
        // const printableObjects = flattenedArray.map(item => ({
        //     transaction_type: item.transaction_type,
        //     transaction_date: item.transaction_date,
        //     transaction_no: item.transaction_no,
        //     customer_name: item.customer_name,
        // }));
        // console.log({ "printableObjects":grouped })
        // console.log({ "printableObjects":printableObjects })
        // printJS({
        //     printable: printableObjects.slice(0,200),
        //     type: 'json',
        //     html:
        //     honorColor: true,
        //     repeatTableHeader: true,
        //     documentTitle: 'Report',
        //     properties: ['transaction_type', 'transaction_date', 'transaction_no', 'customer_name'],
        //     gridHeaderStyle: 'border: 1px solid grey; text-align:left; padding-left:10px',
        //     gridStyle: 'border: 1px solid grey; padding-left:10px',
        // });


        // formatedGroupedData(grouped)
        printJS('printIt', 'html')
        // printJS({
        //     printable: printableObjects.slice(0,200),
        //     type: 'json',
        //     html:
        //     honorColor: true,
        //     repeatTableHeader: true,
        //     documentTitle: 'Report',
        //     properties: ['transaction_type', 'transaction_date', 'transaction_no', 'customer_name'],
        //     gridHeaderStyle: 'border: 1px solid grey; text-align:left; padding-left:10px',
        //     gridStyle: 'border: 1px solid grey; padding-left:10px',
        // });


    };

    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                return {
                    "Account": v.account_search_display_name,
                    "Transaction Type": v.transaction_type,
                    "Transaction No": v.transaction_no,
                    "Transaction Date": moment(v.transaction_date).format("MM-DD-YYYY"),
                    "Customer": v.customer_name,
                    "Debit": numeral(v.debit).format("0,00.00"),
                    "Credit": numeral(v.credit).format("0,00.00"),
                };
            })
        );
    }

    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        worksheet.columns = [
            { header: 'Account', key: 'account_search_display_name', width: 30 },
            { header: 'Transaction Type', key: 'transaction_type', width: 30 },
            { header: 'Transaction No', key: 'transaction_no', width: 30 },
            { header: 'Transaction Date', key: 'transaction_date', width: 15 },
            { header: 'Customer', key: 'customer_name', width: 30 },
            { header: 'Debit', key: 'debit', width: 15 },
            { header: 'Credit', key: 'credit', width: 15 },
        ];


        let totalDebit = 0;
        let totalCredit = 0;
        // Assuming 'data' is an array of objects with properties 'name', 'email', and 'age'
        journalLedger?.payload?.data.forEach((item) => {
            worksheet.addRow({ account_search_display_name: item.account_search_display_name, transaction_type: item.transaction_type, transaction_no: item.transaction_no, transaction_date: item.transaction_date, customer_name: item.customer_name, debit: numeral(item.debit).format("0,00.00"), credit: numeral(item.credit).format("0,00.00") });
            totalDebit += Number(item.debit);
            totalCredit += Number(item.credit);
        });
        worksheet.addRow({ debit: numeral(totalDebit).format("0,00.00"), credit: numeral(totalCredit).format("0,00.00") });

        try {
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // You can modify the filename as needed
            const filename = 'Journal-Ledger.xlsx';

            // Create a download link and trigger a click event to download the file
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();

        } catch (error) {
            console.error(error);
        }
    };

    function generatePDF() {
        var doc = new jsPDF();

        // Define columns and rows
        var columns = ["Account", "Transaction Type", "Transaction No", "Transaction Date", "Customer", "Debit", "Credit"];
        var rows = [];

        // Populate rows with data
        journalLedger?.payload?.data.forEach(item => {
            var row = [item.account_search_display_name, item.transaction_type, item.transaction_no, item.transaction_date, item.customer_name, "$" + numeral(item.debit).format("0,00.00"), "$" + numeral(item.credit).format("0,00.00")];
            rows.push(row);
        });

        const debit = journalLedger?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.debit), 0);
        const credit = journalLedger?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.credit), 0);

        // Add table to the PDF
        doc.autoTable({
            head: [columns],
            body: rows,
            styles: { fontSize: 6 },
        });

        const startY = doc.lastAutoTable.finalY || 10; // Default to 10 if there is no table
        const footerRow = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "Total:", "$" + numeral(debit).format("0,00.00"), "$" + numeral(credit).format("0,00.00")];
        doc.autoTable({
            body: [footerRow],
            startY: startY + 2,
            styles: { fontSize: 6 },
        });

        // Save the PDF
        doc.save("Journal-Ledger.pdf");
    }


    const debitTotal = journalLedger?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.debit), 0);
    const creditTotal = journalLedger?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.credit), 0);



    let journal = journalLedger &&
        journalLedger?.payload?.data?.reduce(function (r, a) {
            r[a.parent_account_no + a.account_search_display_name] =
                r[a.parent_account_no + a.account_search_display_name] || [];
            r[a.parent_account_no + a.account_search_display_name].push(a);
            return r;
        }, Object.create(null));

    const grouped = journal && Object.values(journal);






    const columns = [
        { header: 'Account', key: 'account_search_display_name', width: 30 },
        { header: 'Transaction Type', key: 'transaction_type', width: 30 },
        { header: 'Transaction No', key: 'transaction_no', width: 30 },
        { header: 'Transaction Date', key: 'transaction_date', width: 15 },
        { header: 'Customer', key: 'customer_name', width: 30 },
        { header: 'Debit', key: 'debit', width: 15 },
        { header: 'Credit', key: 'credit', width: 15 },
    ];

    //     const data = [
    //         {
    //             account_search_display_name: 'Kylie',
    //             lastName: 'James',
    //             purchasePrice: 1000,
    //             transaction_type: 900
    //         },
    //         {
    //             account_search_display_name: 'Harry',
    //             lastName: 'Peake',
    //             purchasePrice: 1000,
    //             transaction_type: 1000
    //         }
    //     ];

    //     const workSheetName = 'Worksheet-1';
    //     const workBookName = 'MyWorkBook';

    //     const workbook = new Excel.Workbook();

    //     const saveExcel = async () => {
    //         try {
    //             const fileName = workBookName;

    //             // creating one worksheet in workbook
    //             const worksheet = workbook.addWorksheet(workSheetName);

    //             // add worksheet columns
    //             // each columns contains header and its mapping key from data
    //             worksheet.columns = columns;

    //             // updated the font for first row.
    //             worksheet.getRow(1).font = { bold: true };

    //             // loop through all of the columns and set the alignment with width.
    //             worksheet.columns.forEach(column => {
    //                 column.width = column.header.length + 5;
    //                 column.alignment = { horizontal: 'center' };
    //             });

    //             // loop through data and add each one to worksheet
    //             formatedGroupedData(data).forEach(singleData => {
    //                 worksheet.addRow(singleData);
    //                 worksheet.addRow("singleData");
    //             });

    //             // loop through all of the rows and set the outline style.
    //             worksheet.eachRow({ includeEmpty: false }, row => {
    //                 // store each cell to currentCell
    //                 const currentCell = row._cells;
    // console.log("currentcells",currentCell);
    //                 // loop through currentCell to apply border only for the non-empty cell of excel
    //                 currentCell.forEach(singleCell => {
    //                     // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
    //                     const cellAddress = singleCell._address;

    //                     // apply border
    //                     worksheet.getCell(cellAddress).border = {
    //                         top: { style: 'thin' },
    //                         left: { style: 'thin' },
    //                         bottom: { style: 'thin' },
    //                         right: { style: 'thin' }
    //                     };
    //                 });
    //             });

    //             // write the content using writeBuffer
    //             const buf = await workbook.xlsx.writeBuffer();

    //             // download the processed file
    //             saveAs(new Blob([buf]), `${fileName}.xlsx`);
    //         } catch (error) {
    //             console.error('<<<ERRROR>>>', error);
    //             console.error('Something Went Wrong', error.message);
    //         } finally {
    //             // removing worksheet's instance to create new one
    //             workbook.removeWorksheet(workSheetName);
    //         }
    //     };



    const saveExcel = async () => {
        try {
            const workSheetName = 'Worksheet-1';
            const workBookName = 'MyWorkBook';

            const workbook = new Excel.Workbook();
            const worksheet = workbook.addWorksheet(workSheetName);

            // Set columns for the worksheet
            worksheet.columns = [
                { header: '', key: 'parent', width: 30 },
                { header: '', key: 'account_search_display_name_hd', width: 30 },
                // { header: 'Account', key: 'account_search_display_name', width: 30 },
                { header: 'Transaction Type', key: 'transaction_type', width: 30 },
                { header: '', key: '', width: 3 },
                { header: 'Transaction No', key: 'transaction_no', width: 30 },
                { header: '', key: '', width: 3 },
                { header: 'Transaction Date', key: 'transaction_date', width: 15 },
                { header: '', key: '', width: 15 },
                { header: 'Customer', key: 'customer_name', width: 30 },
                { header: '', key: '', width: 3 },
                { header: 'Debit', key: 'debit', width: 15 },
                { header: '', key: '', width: 3 },
                { header: 'Credit', key: 'credit', width: 15 },
                { header: '', key: '', width: 3 },
            ];

            // Loop through the data array to populate the worksheet
            // console.log("formatedGroupedData(data)",formatedGroupedData(data));
            formatedGroupedData(grouped).forEach(item => {
                if (item.type === 'heading') {
                    // For heading type, add a single cell with parent_account_no in bold
                    worksheet.addRow({ parent: item.parent_account_no }).font = { bold: true };
                    worksheet.addRow({ account_search_display_name_hd: item.account_search_display_name }).font = { bold: true };
                } else if (item.type === 'object') {
                    // For object type, extract data fields and add to a new row
                    const { data } = item;
                    worksheet.addRow({
                        // account_search_display_name: data.account_search_display_name,
                        transaction_type: data.transaction_type,
                        transaction_no: data.transaction_no,
                        transaction_date: data.transaction_date,
                        customer_name: data.customer_name,
                        debit: data.debit,
                        credit: data.credit
                    });
                }
            });

            // Write the workbook to a buffer and save as Excel file
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // You can modify the filename as needed
            const filename = 'Journal-Ledger.xlsx';

            // Create a download link and trigger a click event to download the file
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            // saveAs(new Blob([buffer]), `${workBookName}.xlsx`);
        } catch (error) {
            console.error('Error occurred while generating Excel:', error);
        }
    };

    return (
        <div className="header_class business_header_oms intransit">


            <div className="row justify-content-between business_class_oms distributor">
                <div className="col-3 d-flex align-items-start box-space">
                    <div className="logobrand">
                        <img id="ImgLogo" alt="logo" src={logo} />
                    </div>
                </div>
                <div className="col-4 box-space right-content-header text-right">
                    <div className=" d-flex justify-content-end">
                        <div className=" d-flex justify-content-end mr-8">
                            <div className="print_icon printing_class">
                                <i
                                    onClick={handlePrint}
                                    id="Image1"
                                    title="Print"
                                    alt="Print"
                                    src={btnPrint}
                                    className="fa-solid fa-print"
                                ></i>
                            </div>

                            <div className="print_icon printing_class">
                                <input
                                    type="image"
                                    name="ImgExcel"
                                    alt="Export to Excel"
                                    id="ImgExcel"
                                    title="Export to Excel"
                                    src={xlsx}
                                    onClick={saveExcel}
                                    // className="cursor-not-allowed"
                                    style={{ borderWidth: "0px", height: "14px" }} />
                            </div>
                            {/* <div className="print_icon printing_class">
                                <input
                                    type="image"
                                    name="ImgPDF"
                                    alt="Export to PDF"
                                    id="ImgPDF"
                                    title="Export to PDF"
                                    src={pdf}
                                    onClick={generatePDF}
                                    // className="cursor-not-allowed"
                                    style={{ borderWidth: "0px", height: "18px" }} />
                            </div> */}
                        </div>
                        <span style={{ fontSize: "small", fontWeight: "bolder" }}>
                            General Ledger
                            <br />
                            Rpt # 489
                            <br />
                            <p className=" mb-0">Rpt # 001_V4</p>
                            Login as:{" "}
                            <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                                {userName}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="business_class_oms mb-2 distributor">
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                    <div className="main_form row">
                        <div className="col-12">
                            <hr className="my-1" />
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label htmlFor="" className="labels width30">
                                            Account
                                        </label>
                                        <div style={{ width: "200px" }}>
                                            <Form.Item
                                                name="account_id"
                                                initialValue={"All"}
                                                className="w-100"
                                            >
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    size="small"
                                                    filterSort={(optionA, optionB) => optionB?.value !== null ?
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''}
                                                >
                                                    <Option value={null}>All</Option>
                                                    {chartOfAccount?.payload?.data.map(
                                                        (item) => {
                                                            return (
                                                                <Option
                                                                    key={item.account_id}
                                                                >
                                                                    {`${item.account_id} ${item.account_name}`}
                                                                </Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width30" htmlFor="">From</label>
                                        <div className="w-100">
                                            <Form.Item
                                                name="from_date"
                                                className="full_date"
                                                initialValue={moment().startOf('year')}
                                            >
                                                <DatePicker
                                                    size="small"
                                                    format={"MM-DD-YYYY"}
                                                    defaultValue={moment().format("MM-DD-YYYY")} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className=" d-flex flex-row align-items-baseline">
                                        <label className="labels width30" htmlFor="">To</label>
                                        <div className="w-100">
                                            <Form.Item
                                                name="to_date"
                                                className="full_date"
                                                initialValue={moment().endOf('year')}
                                            >
                                                <DatePicker
                                                    size="small"
                                                    format={"MM-DD-YYYY"} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div style={{ marginTop: "4px", float: "left" }}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="submit-btn-btm m-0"
                                        >
                                            Generate
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-1" />
                        </div>
                    </div>
                </Form>
            </div>
            <div
                className="table_content international_order scrollbar-style"
                style={{ maxWidth: '1150px' }}
            >
                {/* <div style={{ height: "500px", overflow: "scroll", border: "1px solid black" }}> */}

                {/* {journalLedger?.payload?.data !== undefined ?
                        (
                            journalLedger?.payload?.data.length > 0 ? (
                                <div>
                                    <div className="report-box-main">
                                        <table className="report-table-main">
                                            <tr className="report-row-head">
                                                <td style={{ width: "300px", fontSize: 12 }}>Transaction Type</td>
                                                <td style={{ width: "200px", fontSize: 12 }}>Transaction date</td>
                                                <td style={{ fontSize: 12 }}>Transaction #</td>
                                                <td style={{ fontSize: 12 }}>Customer Name</td>
                                                <td style={{ fontSize: 12 }}>Memo</td>
                                                <td style={{ fontSize: 12 }}>Split</td>
                                                <td style={{ textAlign: "right", fontSize: 12 }}>Debit</td>
                                                <td style={{ textAlign: "right", fontSize: 12 }}>Credit</td>
                                                <td style={{ textAlign: "right", fontSize: 12 }}> Balance</td>
                                            </tr>
                                            {

                                                grouped?.map((v, idx) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td className="report-hd">{v[0]?.parent_account_no}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ paddingLeft: "10px !important" }}>{v[0]?.account_search_display_name}</td>

                                                            </tr>
                                                            {v?.map((val) => {
                                                            return (
                                                                <tr>
                                                                    <td style={{ fontSize: 10 }}>
                                                                        {val.transaction_type}
                                                                    </td>
                                                                    <td style={{ fontSize: 10 }}>
                                                                        {val.transaction_date}
                                                                    </td>
                                                                    <td style={{ fontSize: 10 }}>
                                                                        {val.transaction_no}
                                                                    </td>
                                                                    <td style={{ fontSize: 10 }}>
                                                                        {val.customer_name}
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                    <td style={{ textAlign: "right", fontSize: 10 }}>
                                                                        {numeral(val.debit).format("0,00.00")}
                                                                    </td>
                                                                    <td style={{ textAlign: "right", fontSize: 10 }}>
                                                                        {numeral(val.credit).format("0,00.00")}
                                                                    </td>
                                                                    <td style={{ textAlign: "right", fontSize: 10 }}>
                                                                        {numeral(val.balance).format("0,00.00")}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}

                                                        </>
                                                    );
                                                })}
                                        </table>
                                    </div>
                                </div>
                            ) : (
                                <div className="no-Data">
                                    <h1>No Data</h1>
                                </div>
                            )
                        ) : (
                            loading && (
                                <div className="loader" style={{ marginTop: "20px" }}>
                                    <Spin size="medium" />
                                </div>
                            )
                        )} */}
                {/* </div> */}
            </div>

            <div style={{ width: "100%" }} ref={tableRef}>
                {
                    journalLedger?.payload?.data.length > 0 ? (
                        <div>
                            <VirtualizedAccountsListComp data={grouped} />
                        </div>

                    ) : (

                        loading ? (
                            <div className="loader" style={{ marginTop: "20px" }}>
                                <Spin size="medium" />
                            </div>
                        ) :
                            <div className="no-Data">
                                <h1>No Data</h1>
                            </div>
                    )
                }
            </div>
        </div>
    );
};


export default JournalLedgerNew;